import { getLocalizedString } from "@icarius-localization/strings";
import { fieldTypes, TABS } from "@icarius-utils/constants";

const NAME = "DIRECTORY_PAGE";

export const directoryEmployeeFields = [
  {
    name: "NRO INTERNO",
    type: fieldTypes.text,
    label: getLocalizedString("internalNumber"),
    tab: TABS.personal,
  },
  {
    name: "EMAIL",
    type: fieldTypes.email,
    label: getLocalizedString("email"),
    tab: TABS.personal,
  },
  {
    name: "CELULAR",
    type: fieldTypes.phone,
    label: getLocalizedString("cellphone"),
    tab: TABS.personal,
  },
  {
    name: "ESTADO CIVIL",
    type: fieldTypes.text,
    label: getLocalizedString("civilState"),
    tab: TABS.personal,
  },
  {
    name: "SEXO",
    type: fieldTypes.text,
    label: getLocalizedString("sex"),
    tab: TABS.personal,
  },
  {
    name: "NACIONALIDAD",
    type: fieldTypes.text,
    label: getLocalizedString("nationality"),
    tab: TABS.personal,
  },
  {
    name: "FECHA DE NACIMIENTO",
    type: fieldTypes.text,
    label: getLocalizedString("bornDate"),
    tab: TABS.personal,
  },
  {
    name: "LUGAR DE PAGO",
    type: fieldTypes.text,
    label: getLocalizedString("paymentLocation"),
    tab: TABS.location,
  },
  {
    name: "LUGAR DE TRABAJO",
    type: fieldTypes.text,
    label: getLocalizedString("jobLocation"),
    tab: TABS.location,
  },
  {
    name: "GERENCIA",
    type: fieldTypes.text,
    label: getLocalizedString("management"),
    tab: TABS.location,
  },
  {
    name: "DEPARTAMENTO",
    type: fieldTypes.text,
    label: getLocalizedString("department"),
    tab: TABS.location,
  },
  {
    name: "SECCION",
    type: fieldTypes.text,
    label: getLocalizedString("section"),
    tab: TABS.location,
  },
  {
    name: "TURNO",
    type: fieldTypes.text,
    label: getLocalizedString("shift"),
    tab: TABS.time,
  },
  {
    name: "HORARIO",
    type: fieldTypes.text,
    label: getLocalizedString("scheduleProgrammed"),
    tab: TABS.time,
  },
  {
    name: "INASISTENCIA",
    type: fieldTypes.text,
    label: getLocalizedString("assistanceStatus"),
    tab: TABS.time,
  },
];

export default NAME;

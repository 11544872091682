import {
  GET_DATA,
  GET_DATA_FULFILLED,
  GET_DATA_REJECTED,
  LIKE,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { likeTypes } from "@icarius-utils/constants";
import { getDirectoryAPI, likeEmployeeAPI } from "@icarius-connection/api";
import { getOpinion } from "@icarius-utils/like";

export const getPeopleTableRows = () => async (dispatch) => {
  dispatch({ type: GET_DATA });

  try {
    let response = await getDirectoryAPI();
    let employees = response.data && response.data.directory;
    let dateFormat = response.data.date_format;
    let locale = response.data.currency_localization;
    let helpDocumentation = response.data.help_documentation;

    dispatch({
      type: GET_DATA_FULFILLED,
      payload: {
        locale,
        dateFormat,
        helpDocumentation,
        employees: employees.map((employee) => ({
          ...employee,
          OPINION: getOpinion(employee),
        })),
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const likeRequestAction = (id, like, oldValue) => async (dispatch) => {
  try {
    let response = await likeEmployeeAPI({
      colaboratorCode: id,
      likeOrDislike: oldValue ? likeTypes.unset : like,
    });

    dispatch({
      type: LIKE,
      payload: { id, like }
    });
    return response;
  } catch (e) { }
};
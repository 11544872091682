import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading;
export const getEmployees = store => store[NAME].employees;
export const getHelp = store => store[NAME].helpDocumentation;
export const gettingPeopleRows = store => store[NAME].gettingPeopleRows;
export const getLocale = store => store[NAME].locale;
export const getDateFormat = store => store[NAME].dateFormat;
export const gettingEmployee = store => store[NAME].gettingEmployee;


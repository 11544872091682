import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import Likes from "@icarius-common/likes";
import QR from "@icarius-common/qr";
import ProfileImg from "@icarius-common/profileImg";

const UserSummary = (props) => {

    const {
        employee,
        handleLikeRequest,
    } = props;

    const color = useSelector(getAppColor);
    const societySelected = useSelector(getSocietySelected);

    const qr = employee &&
        "BEGIN:VCARD\n" +
        "VERSION:3.0\n" +
        "N:" + employee["APELLIDO Y NOMBRE"] + "\n" +
        "ORG:" + societySelected.name +
        (employee["CELULAR"] ? "\nTEL:" + employee["CELULAR"] : "") + "\n" +
        "EMAIL:" + employee["EMAIL"] + "\n" +
        "END:VCARD\n";

    return (
        <Grid container item xs={12} alignItems="center" spacing={2}>
            <Grid container alignItems="center" justify="center" item sm={12} md={2}>
                <div className={"photo-container"}>
                    <ProfileImg
                        image={employee["FOTOGRAFIA"]}
                        size={100}
                    />
                </div>
            </Grid>
            <Grid container direction="column" item sm={12} md={8}>
                <Typography
                    style={{
                        fontWeight: 200,
                        fontSize: '20px',
                        lineHeight: "28px",
                    }}
                    className={"whiteText"}
                    variant="subtitle1"
                >
                    {employee["APELLIDO Y NOMBRE"]}
                </Typography>
                {
                    employee["MANAGER"] && employee["MANAGER"] !== "-" &&
                    <Typography
                        style={{
                            color: color,
                            fontWeight: 400,
                            fontSize: '20px',
                            lineHeight: "28px",
                        }}
                    >
                        {"Manager: " + employee["MANAGER"]}
                    </Typography>
                }
                {
                    employee["ESTADO"] &&
                    <Typography
                        className={"whiteText"}
                        style={{
                            fontWeight: 200,
                            fontSize: '20px',
                            lineHeight: "28px",
                        }}
                    >
                        {employee["ESTADO"] === "Vigente" ? getLocalizedString("vigente") : getLocalizedString("noVigente")}
                    </Typography>
                }
                <div style={{ paddingTop: 10 }}>
                    <Likes
                        active={employee["ESTADO"] === "Vigente"}
                        value={employee["OPINION"]}
                        handleLike={(like, extra) => handleLikeRequest(employee["APELLIDO Y NOMBRE"], like, extra)}
                    />
                </div>
            </Grid>
            <Grid container item sm={12} md={2} justify="center" alignItems="center">
                <QR
                    value={qr}
                    hideLabel
                />
            </Grid>
        </Grid>
    )
}

export default UserSummary;
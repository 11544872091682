import React from "react";
import { Typography, DialogContent, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DynamicInput from "@icarius-common/dynamicInputs";
import { directoryEmployeeFields } from "@icarius-pages/directory/constants";
import { TABS } from "@icarius-utils/constants";
import UserSummary from "./userSummary";

export const TabPanel = ({ children, title }) => {
  return (
    <Grid container style={{ marginBottom: 30 }}>
      <Grid item sm={12} md={3}>
        <Typography variant="h6" className="whiteText" style={{ marginTop: 5, textTransform: "uppercase", paddingBottom: 20 }}>
          {title}
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        {children}
      </Grid>
    </Grid>
  );
}

const CustomDialogContent = (props) => {

  const {
    employee,
    handleLikeRequest,
  } = props;

  return (
    <DialogContent id={"employee-dialog-content"} style={{ padding: "0px 48px 8px 48px" }}>
      {
        employee &&
        <Grid container>
          <Grid item xs={12}>
            <UserSummary
              employee={employee}
              handleLikeRequest={handleLikeRequest}
            />
            <section style={{ marginTop: 15, width: "100%" }}>
              {
                [
                  {
                    key: TABS.personal,
                    title: getLocalizedString("personales"),
                  },
                  {
                    key: TABS.location,
                    title: getLocalizedString("ubicacion"),
                  },
                  {
                    key: TABS.time,
                    title: getLocalizedString("tiempos"),
                  },
                ].map((tab) => {
                  return (
                    <TabPanel title={tab.title} key={tab.key}>
                      {
                        directoryEmployeeFields
                          .filter((field) => field.tab === tab.key)
                          .map((field, index) => {

                            if (["HORARIO", "INASISTENCIA"].includes(field.name) && !employee[field.name]) return null;

                            return (
                              <Grid item xs={12} sm={6} key={index}>
                                <DynamicInput
                                  label={field.label}
                                  value={employee[field.name]}
                                  readOnlyType={field.type}
                                />
                              </Grid>
                            )
                          })
                      }
                    </TabPanel>
                  )
                })
              }
            </section>
          </Grid>
        </Grid>
      }
    </DialogContent>
  );
}

export default CustomDialogContent;
import React, { useState } from "react";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";
import { Grid, Typography, Menu } from "@material-ui/core";
import { VariableSizeList as List } from 'react-window';
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import useGetScreenHeight from "@icarius-utils/hooks/useGetScreenHeight";

const onMediaFallback = event => event.target.src = DefaultImage;

const CardsOnSide = ({ onEmployeeClicked, peopleRows, color }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const screenHeight = useGetScreenHeight();

    const Row = ({ index, style }) => (
        <div style={style} key={index}>
            <Grid style={{ margin: "15px 0px", marginBottom: 0 }} container direction="row" >
                <Grid style={{ cursor: "pointer" }} container direction="row" onClick={() => onEmployeeClicked(peopleRows[index])}>
                    <Grid container justify="center" alignItems="center" item xs={3}>
                        {
                            peopleRows[index]["FOTOGRAFIA"] && peopleRows[index]["FOTOGRAFIA"] !== "-" ?
                                <img
                                    style={{ height: "44px", width: "44px", borderRadius: "50%", border: "2px solid var(--profileBorder)" }}
                                    src={IMAGES_ENDPOINT + peopleRows[index]["FOTOGRAFIA"]}
                                    onError={onMediaFallback}
                                    alt={peopleRows[index]["APELLIDO Y NOMBRE"]}
                                />
                                :
                                <div style={{ height: "44px", width: "44px", borderRadius: "50%", border: "2px solid var(--profileBorder)", background: color }} />
                        }
                    </Grid>
                    <Grid style={{ paddingLeft: 5, paddingRight: 5 }} container item xs={9} direction="column" justify="center" alignItems="flex-start">
                        <Grid item>
                            <Typography style={{ fontWeight: 500, fontSize: "14px" }} className="whiteText">
                                {peopleRows[index]["APELLIDO Y NOMBRE"]}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: 200, fontSize: "12px" }} className="whiteText">
                                {peopleRows[index]["LUGAR DE TRABAJO"]}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <div style={{ width: '100%', margin: 20 }}>
                    <div style={{ borderBottom: `1px solid ${color}` }} />
                </div>
            </Grid>
        </div>
    )

    return (
        <>
            <CustomIconButton
                title={'Ver colaboradores de la lista'}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                type="preview"
            />
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List
                    className="List"
                    height={screenHeight - 60 - 200}
                    itemCount={peopleRows.length}
                    itemSize={() => 108}
                    width={300}
                >
                    {Row}
                </List>
            </Menu>
        </>
    )
}

export default CardsOnSide;

import { likeEmployee } from "@icarius-utils/like";
import {
  GET_DATA,
  GET_DATA_FULFILLED,
  GET_DATA_REJECTED,
  LIKE,
} from "./actionTypes";

const initialState = {
  isLoading: false,
  employees: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  helpDocumentation: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA:
      return { ...state, isLoading: true };
    case GET_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        employees: action.payload.employees,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
        helpDocumentation: action.payload.helpDocumentation,
      };
    case GET_DATA_REJECTED:
      return { ...state, isLoading: false };

    case LIKE: {
      let likeEmployeeData = likeEmployee(state.employees, action.payload.id, action.payload.like, "APELLIDO Y NOMBRE");

      return {
        ...state,
        employees: likeEmployeeData.updatedEmployees,
      };
    }
    default:
      return state;
  }
}

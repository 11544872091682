import React from "react";
import DialogContent from "./dialogContent";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle } from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import { getLocalizedString } from "@icarius-localization/strings";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const StyledDialog = withStyles({
  paper: {
    minHeight: "90vh",
    maxHeight: "90vh",
  },
})(Dialog);

const DirectoryDialog = (props) => {

  const {
    open,
    employee,
    handleClose,
    handleLikeRequest,
  } = props;

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("collabData")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent
        employee={employee}
        handleLikeRequest={handleLikeRequest}
      />
    </StyledDialog>
  )
}

export default DirectoryDialog;

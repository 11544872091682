import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";

export default [
    {
        pages: [paths.directory],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.directory],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.directory],
        config: {
            headerName: getLocalizedString("email"),
            field: "EMAIL",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.directory],
        config: {
            headerName: getLocalizedString("cellphone"),
            field: "CELULAR",
            filter: "agSetColumnFilter",
            valueGetter: function (params) {
                return (params.data && (params.data["CELULAR"] || getLocalizedString("notAvailable"))) || "";
            },
        }
    }, {
        pages: [paths.directory],
        config: {
            headerName: getLocalizedString("nationality"),
            field: "NACIONALIDAD",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.directory],
        config: {
            headerName: getLocalizedString("management"),
            field: "GERENCIA",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.directory],
        config: {
            headerName: getLocalizedString("department"),
            field: "DEPARTAMENTO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
]
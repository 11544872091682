import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppColor,
  getIsAbsentCollaboratorsEnabled,
  getUserData,
} from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getIsExportingGrid } from "src/app/selectors";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { AbsentCollaboratorsDialog } from "@icarius-common/absentCollaboratorsDialog";
import { getPeopleTableRows, likeRequestAction } from "../actions";
import { getEmployees, getIsLoading, getDateFormat, getLocale } from "../selectors";
import DirectoryDialog from "./dialog";
import CardsOnSide from "./employeeCards/cardsOnSide";

const Directory = () => {

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [directoryDialogIsOpen, setDirectoryDialogIsOpen] = useState(false);
  const [absentCollaboratorsDialogIsOpen, setAbsentCollaboratorsDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const isExportingGrid = useSelector(getIsExportingGrid);
  const employees = useSelector(getEmployees);
  const color = useSelector(getAppColor);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const userData = useSelector(getUserData);
  const isAbsentCollaboratorsEnabled = useSelector(getIsAbsentCollaboratorsEnabled);

  const sideBarPeople = useMemo(() => filteredRows.length > 0 ? filteredRows : employees, [filteredRows, employees]);

  useEffect(() => {
    dispatch(getPeopleTableRows());
  }, [dispatch])

  const handleSetFilteredRows = (params) => {
    const nodes = [];
    params.api.forEachNodeAfterFilterAndSort(node => nodes.push(node.data));
    setFilteredRows(nodes);
  }

  const handleCardClick = (employee) => {
    if (employee) {
      setDirectoryDialogIsOpen(true);
      setSelectedEmployee(employee);
    }
  };

  const handleCloseDialog = () => {
    setDirectoryDialogIsOpen(false);
    setSelectedEmployee(null);
  }

  const handleLikeRequest = (id, like, oldValue) => {
    if (id !== userData.code)
      dispatch(likeRequestAction(id, like, oldValue));
  };

  const handleRowClick = (employeeRow) => {
    setDirectoryDialogIsOpen(true);
    setSelectedEmployee(employeeRow.data);
  };

  const filterItem = () =>
    <CardsOnSide
      peopleRows={sideBarPeople}
      onEmployeeClicked={handleCardClick}
      color={color}
    />

  const customHeader = () => isAbsentCollaboratorsEnabled &&
    <CustomIconButton
      title={"Colaboradores ausentes"}
      onClick={() => setAbsentCollaboratorsDialogIsOpen(true)}
    />

  return (
    <CommonPage
      menuItems={[filterItem]}
      customHeader={customHeader}
      onFilterChanged={handleSetFilteredRows}
      rowData={employees}
      title={getLocalizedString("directoryTableTitle")}
      gridTitle={getLocalizedString("directory")}
      locale={locale}
      dateFormat={dateFormat}
      columnDefPage={paths.directory}
      handleRowClick={handleRowClick}
      isLoading={isLoading || isExportingGrid}
      hasHelp
      hasExpand
      hasSelectAll
      audioName={"Directorio"}
    >
      {
        directoryDialogIsOpen &&
        <DirectoryDialog
          open={directoryDialogIsOpen}
          employee={selectedEmployee}
          handleClose={handleCloseDialog}
          handleLikeRequest={handleLikeRequest}
        />
      }
      {
        absentCollaboratorsDialogIsOpen &&
        <AbsentCollaboratorsDialog
          open={absentCollaboratorsDialogIsOpen}
          handleClose={() => setAbsentCollaboratorsDialogIsOpen(false)}
        />
      }
    </CommonPage>
  );
}

export default Directory;
